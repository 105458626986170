import React from 'react'
import '../styles/ContentBox.css'
import '../styles/CVMakerPage.css'

export function JSONViewer() {
	return (
		<div className='view-editor'>
			<div className='header-general'>
				<div className='header-title-general'>JSON Viewer</div>
			</div>
			<div className='content-box'>
				
			</div>
		</div>
	)
}