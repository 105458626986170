import React from 'react'
import "../styles/CVPage.css"

export function CVPage() {
	return (
		<div className="container">
			<div className="header">
				<div className="full-name">
					<span className="first-name">David</span>
					<span className="last-name">Baranovski</span>
				</div>
				<div className="contact-info">
					<span className="email">Email: </span>
					<span className="email-val">violin19991@gmail.com</span>
					<span className="separator"></span>
					<span className="phone">Phone: </span>
					<span className="phone-val">+370 67231748</span>
				</div>

				<div className="about">
					<span className="position">Full-Stack Developer </span>
					<span className="desc">
						I am a front-end developer with more than 2 years of experience writing apex class, lightning
						components (aura and LWC), html, css, and js. I'm motivated, result-focused and seeking a successful
						team-oriented company with opportunity to grow.
					</span>
				</div>
			</div>
			<div className="details">
				<div className="section">
					<div className="section__title">Experience</div>
					<div className="section__list">
						<div className="section__list-item">
							<div className="left">
								<div className="name">KlowdBox</div>
								<div className="addr">San Fr, CA</div>
								<div className="duration">Jan 2011 - Feb 2015</div>
							</div>
							<div className="right">
								<div className="name">Fr developer</div>
								<div className="desc">did This and that</div>
							</div>
						</div>
						<div className="section__list-item">
							<div className="left">
								<div className="name">Akount</div>
								<div className="addr">San Monica, CA</div>
								<div className="duration">Jan 2011 - Feb 2015</div>
							</div>
							<div className="right">
								<div className="name">Fr developer</div>
								<div className="desc">did This and that</div>
							</div>
						</div>

					</div>
				</div>
				<div className="section">
					<div className="section__title">Education</div>
					<div className="section__list">
						<div className="section__list-item">
							<div className="left">
								<div className="name">Sample Institute of technology</div>
								<div className="addr">San Fr, CA</div>
								<div className="duration">Jan 2011 - Feb 2015</div>
							</div>
							<div className="right">
								<div className="name">Fr developer</div>
								<div className="desc">did This and that</div>
							</div>
						</div>
						<div className="section__list-item">
							<div className="left">
								<div className="name">Akount</div>
								<div className="addr">San Monica, CA</div>
								<div className="duration">Jan 2011 - Feb 2015</div>
							</div>
							<div className="right">
								<div className="name">Fr developer</div>
								<div className="desc">did This and that</div>
							</div>
						</div>

					</div>

				</div>
				<div className="section">
					<div className="section__title">Projects</div>
					<div className="section__list">
						<div className="section__list-item">
							<div className="name">DSP</div>
							<div className="text">I am a front-end developer with more than 3 years of experience writing html,
								css, and js. I'm motivated, result-focused and seeking a successful team-oriented company
								with opportunity to grow.</div>
						</div>

						<div className="section__list-item">
							<div className="name">DSP</div>
							<div className="text">I am a front-end developer with more than 3 years of experience writing html,
								css, and js. I'm motivated, result-focused and seeking a successful team-oriented company
								with opportunity to grow. <a href="/login">link</a>
							</div>
						</div>
					</div>
				</div>

				<div id="skills" className="section"></div>
				<div className="skills">
					<div className="skills__item">

						<div className="right">
                        <div className="d"></div>            
                    </div>

					</div>
				</div>

				<div className="section">
					<div className="section__title">
						Interests
					</div>
					<div className="section__list">
						<div className="section__list-item">
							Guitar, programming, experimenting with css design.
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}