import React from 'react'
import '../styles/ContentBox.css'
import '../styles/CVMakerPage.css'
import { CVPage } from '../components/CVPage'

export function CVMakerPage() {
	return (
		<div className='view-editor'>
			<div className='header-general'>
				<div className='header-title-general'>CV Maker</div>
			</div>
			<div className='content-box'>
				<div className='content-devider'>
					<div className='content-side'>
						<CVPage />
					</div>
					<div className='edit-side'>
						<strong>Edit part</strong>
					</div>
				</div>
			</div>
		</div>
	)
}