import React from 'react'
import logo from '../logo.svg'
import { Link } from 'react-router-dom'
import '../App.css'

export function MenuPage() {
	return (
		<>
			<div className="App">
				<div className="App-header">
					<img src={logo} className="App-logo" alt="logo" />
					<nav className=''>
						<span>
							<Link to='/json_viewer' className='Nav-Button'>JSON viewer</Link>
							<Link to='/cv' className='Nav-Button'>Maker</Link>
							<Link to='/about' className='Nav-Button'>About</Link>
						</span>
					</nav>
				</div>
			</div>
		</>
	)
}